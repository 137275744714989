import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from './auth.service';

/**
 * Whether the current user can access to a view or not
 */
export const authGuard: CanActivateFn = (_route, { url }) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isUserLogged = authService.loggedIn();

  if (!isUserLogged) {
    authService.redirectUrl = url;
    const redirectUrl = authService.isValidHubDomain() && router.url !== '/hub' ? '/hub' : '/login';

    return router.parseUrl(redirectUrl);
  }

  return isUserLogged;
};
