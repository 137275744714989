import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { HttpBackend, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorStateMatcher, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { createTranslateLoader, formatLanguage } from 'src/utils/translate';
import { SwiperModule } from 'swiper/angular';

import { MyErrorStateMatcher } from '../providers/my-error-state-matcher.provider';
import { ApiService } from '../services/api.service';
import { httpErrorInterceptor } from '../services/http-error.interceptor';
import { SubdomainConfigService } from '../services/subdomain-config.service';
import { LoginModule } from '../shared-components/ng-login/login.module';
import { FormatBytesPipe } from '../shared-components/pipes/format-bytes.pipe';
import { StripHtmlPipe } from '../shared-components/pipes/strip-html-page';
import { routes } from './app.routes';

function getGoogleTagManagerId(apiService: ApiService, subdomainConfigService: SubdomainConfigService): string {
  const subdomain = apiService.getApiHostname();
  const config = subdomainConfigService.getConfigForSubdomain(subdomain);

  return config.gtmId ? config.gtmId : 'createGTMToken';
}

export const globalImports = [
  provideHttpClient(withInterceptors([httpErrorInterceptor])),
  provideRouter(routes),
  importProvidersFrom([
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend]
      },
      defaultLanguage: formatLanguage(navigator.language)
    })
  ])
];

export const materialImports = [
  importProvidersFrom([
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatMenuModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatCardModule,
    MatPaginatorModule,
    MatExpansionModule,
    NgxPaginationModule,
    MatTabsModule,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatSelectSearchModule
  ])
];

export const appConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      LoginModule,
      SwiperModule,
      InfiniteScrollModule,
      CdkAccordionModule,
      FormsModule
    ),
    globalImports,
    materialImports,
    provideAnimations(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: LOCALE_ID, useValue: formatLanguage(navigator.language) },
    { provide: MAT_DATE_LOCALE, useValue: formatLanguage(navigator.language) },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500 } },
    FormatBytesPipe,
    StripHtmlPipe,
    { provide: ErrorStateMatcher, useValue: new MyErrorStateMatcher() },
    { provide: 'googleTagManagerId', useFactory: getGoogleTagManagerId, deps: [ApiService, SubdomainConfigService] }
  ]
} satisfies ApplicationConfig;
